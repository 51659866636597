import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ContactPageWrapper from "./Components/Pages/ContactPage/ContactPageWrapper";
import MainPageWrapper from "./Components/Pages/MainPage/MainPageWrapper";
import ScrollTopRouter from "./Components/Pages/ScrollTopRouter";
import CoursePageWrapper from "./Components/Pages/CourseDetailsPage/CoursePageWrapper";
import AllCoursesPageWrapper from "./Components/Pages/CoursesPage/AllCoursesPageWrapper";
import AboutUsPageWrapper from "./Components/Pages/AboutUsPage/AboutUsPageWrapper";
import BusinessPageWrapper from "./Components/Pages/BusinessPage/BusinessPageWrapper";
import EduSchoolPageWrapper from "./Components/Pages/EduSchoolPage/EduSchoolPageWrapper";
import RegisterCoursePage from "./Components/Pages/RegistrationPage/RegisterCoursePage";
import RegisterEduSchool from "./Components/Pages/RegistrationPage/RegisterEduSchool";

const App = () => {
  const location = useLocation();

  // Set different titles and descriptions based on the current route
  let title = "Educity - Home";
  let description =
    "ედუსითი არის კომპანია, სადაც შეძლებთ შეისწავლოთ სხვადასხვა პროფესია ნულიდან პროფესიონალი მენტორების დახმარებით.";

  if (location.pathname === "/contacts") {
    title = "Educity - Contacts";
    description =
      "შეავსეთ ფორმა და დარეგისტრირდით ჩვენთან ხელმისაწვდომ კურსებზე, რომლებსაც შეისწავლით ნულიდან და ხარისხიანად.";
  } else if (location.pathname === "/aboutus") {
    title = "Educity - Aboutus";
    description =
      "ჩვენ ვართ კომპანია, რომელსაც ჰყავს უამრავი პარტნიორი. მათ შორის არის თბილისის მერია. აქ დამთავრებული კურსის შემდგომ გადმოგეცემათ სერთიფიკატი, რაც გამოგადგებათ მომავალში.";
  } else if (location.pathname === "/business") {
    title = "Educity - Business";
    description =
      "დაინტერესდით ჩვენთან პარტნიორობით? შეავსეთ მოცემული ფორმა, წარმოაჩინეთ თქვენი თავი და გახდით ჩვენი პარტნიორი.";
  } else if (location.pathname === "/courses") {
    title = "Educity - Courses";
    description =
      "ჯერ არ გადაგიწყვეტიათ რომელი კურსი? გადახედეთ ყველა კურსის დეტალს, გაეცანით მას კარგად და აირჩიეთ რომელიც თქვენ შეგეფერებათ.";
  } else if (location.pathname === "/eduschool") {
    title = "Educity - EduSchool";
    description =
      "EduSchool არის განსაკუთრებული პროგრამა, სადაც მოსწავლეები შეძლებენ საფუძვლიანად შეისწავლონ შერჩეული პროფესია. სწავლა შესაძლებელია ონლაინ თქვენთვის სასურველი დღეების რაოდენობით.";
  } else if (location.pathname === "/course-register") {
    title = "Educity - Course Registration";
    description =
      "შეავსეთ ფორმა და დარეგისტრირდით ჩვენთან ხელმისაწვდომ კურსებზე, რომლებსაც შეისწავლით ნულიდან და ხარისხიანად.";
  } else if (location.pathname === "/eduschool-register") {
    title = "Educity - EduSchool Registration";
    description =
      "შეავსეთ ფორმა და დარეგისტრირდით EduSchool-ის პროგრამაში, სადაც შეისწავლით თქვენთვის სასურველ კურსს უმოკლეს პერიოდში და ხარისხიანად.";
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <ScrollTopRouter />
      <Routes>
        <Route exact path="/" element={<MainPageWrapper />} />
        <Route exact path="/aboutus" element={<AboutUsPageWrapper />} />
        <Route exact path="/contacts" element={<ContactPageWrapper />} />
        <Route
          exact
          path="/course/details/:id"
          element={<CoursePageWrapper />}
        />
        <Route exact path="/courses" element={<AllCoursesPageWrapper />} />
        <Route exact path="/business" element={<BusinessPageWrapper />} />
        <Route exact path="/eduschool" element={<EduSchoolPageWrapper />} />
        <Route exact path="/course-register" element={<RegisterCoursePage />} />
        <Route
          exact
          path="/eduschool-register"
          element={<RegisterEduSchool />}
        />
      </Routes>
    </>
  );
};

export default App;
