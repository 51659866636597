import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainButton from "../../Buttons/MainButton";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { ListInput } from "konsta/react";

import "./RegisterCourse.css";

const RegisterEduSchool = () => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState();
  let [where_did_you_hear, setWhere_did_you_hear] = useState("");
  let [lesson_amount, setLesson_amount] = useState("კვირაში 1 დღე");
  let [why_cours, setWhy_cours] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 450);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 450);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(phoneNumber);
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_9u8ccoq";
    const publichKey = "Tc_ySsnDKjbaH61v_";
    const templateId = "template_7430iug";

    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phoneNumber,
      where_did_you_hear: where_did_you_hear,
      lesson_amount: lesson_amount,
      why_cours: why_cours,
    };

    emailjs
      .send(serviceID, templateId, templateParams, publichKey)
      .then((response) => {
        console.log("Email sent succesfully!", response);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setWhere_did_you_hear("");
        setLesson_amount("კვირაში 1 დღე");
        setWhy_cours("");
      })
      .catch((error) => {
        console.error("Error sending email: ", error);
      });
  };

  return (
    <div className="course-register-form-wrapper">
      <div className="course-register-form-header">
        <div>sssssssssss</div>
        <h2 className="course-register-form-title">რეგისტრაცია</h2>
        <Link to="/eduschool">
          <button className="course-register-form-close">
            <CloseIcon />
          </button>
        </Link>
      </div>

      <form className="form-registration">
        <div className="form-name-email-wrapper">
          <input
            type="text"
            placeholder="თქვენი სახელი"
            className="name-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="თქვენი ელ.ფოსტა"
            className="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <input
          type="text"
          placeholder="ტელეფონის ნომერი"
          className="number-input"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="საიდან გაიგეთ ჩვენ შესახებ?"
          className="about-us-input"
          value={where_did_you_hear}
          onChange={(e) => setWhere_did_you_hear(e.target.value)}
          required
        />

        <div className="lesson-amount-input">
          <ListInput
            type="select"
            value={lesson_amount}
            onChange={(e) => setLesson_amount(e.target.value)}
          >
            <option value="კვირაში 1 დღე">კვირაში 1 დღე</option>
            <option value="კვირაში 2 დღე">კვირაში 2 დღე</option>
            <option value="კვირაში 3 დღე">კვირაში 3 დღე</option>
          </ListInput>
        </div>

        <input
          type="text"
          placeholder="რატომ გსურთ ამ კურსის გავლა?"
          className="why-input"
          value={why_cours}
          onChange={(e) => setWhy_cours(e.target.value)}
          required
        />

        <div onClick={handleSubmit} style={{ alignSelf: "flex-end" }}>
          {isLargeScreen ? (
            <MainButton
              type="submit"
              text="კურსზე რეგისტრაცია"
              width="263px"
              height="65px"
              fontSize="18px"
            />
          ) : (
            <MainButton
              type="submit"
              text="კურსზე რეგისტრაცია"
              width="203px"
              height="45px"
              fontSize="16px"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default RegisterEduSchool;
