import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import MainButton from "../../Buttons/MainButton";

import "./BusinessPage.css";

export default function BusinessInfo() {
  let [name, setName] = useState("");
  let [lastName, setLastName] = useState("");
  let [companyName, setCompanyName] = useState("");
  let [phoneNumber, setPhoneNumber] = useState();
  let [email, setEmail] = useState("");
  let [text, setText] = useState("");

  // console.log(phoneNumber);
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_8vi3f25";
    const publichKey = "el0GIfbIPcZM4C10m";
    const templateId = "template_2up713r";

    const templateParams = {
      from_name: name,
      from_lastName: lastName,
      from_companyname: companyName,
      from_phone: phoneNumber,
      from_email: email,
      message: text,
    };

    emailjs
      .send(serviceID, templateId, templateParams, publichKey)
      .then((response) => {
        console.log("Emai sent succesfully!", response);
        setName("");
        setLastName("");
        setCompanyName("");
        setPhoneNumber("");
        setEmail("");
        setText("");
      })
      .catch((error) => {
        console.error("Error sending email: ", error);
      });
  };

  const Employment =
    "ჩვენ გაწვდით საჭირო უნარებით აღჭურვილ კადრს, რომლებიც მზად არიან დიდი მოტივაციით ჩაერთონ კომპანიის საქმიანობაში და შეიტანონ წვლილი მის განვითარებაში. მიმართულებები:  ვებ დეველოპერი, ბექ-ენდ დეველოპერი,  ციფრული პროდუქტების ტესტერი, გრაფიკული დიზაინერი, UI/UX დიზაინერი, გაყიდვები მენეჯერი, სასტუმროს მენეჯერი. ფრონტ-ენდ დეველოპერი.";
  const CorporateOffers =
    "კომპანიებს ვთავაზობთ კადრების გადამზადებას, კორპორატიულ ფასდაკლებებს, მომსახურებას ტექ ინდუსტრიაში, ვორქშოფებს და საერთო პროექტებს.";
  const SocialResponsibility =
    "კორპორატიული სოციალური პასუხისმგებლობის ფარგლებში, შეგიძლით დაგვიკავშირდეთ და დააფინანსოთ განათლება იმ ადამიანებისთვის, ვისაც სჭირდება ახალი ცხოვრების დაწყება და კარიერის გაგრძელება ტექნოლოგიურ სფეროში";
  const [originalText, setOriginalText] = useState(Employment);

  const handleClick = (e) => {
    console.log(e);
    if (e === "Employment") {
      setOriginalText(Employment);
    } else if (e === "CorporateOffers") {
      setOriginalText(CorporateOffers);
    } else {
      setOriginalText(SocialResponsibility);
    }
  };
  return (
    <div className="BusinesContainer3">
      <h1 className="becomePartner">გახდი პარტნიორი</h1>
      <div className="BusinesContainer3Container">
        <div className="BusinesContainer3InfoCont">
          <div className="BusinesContainer3InfoContBtn">
            <div onClick={() => handleClick("Employment")}>
              <MainButton
                text="დასაქმება"
                width="211px"
                height="65px"
                fontSize="18px"
              />
            </div>
            <div onClick={() => handleClick("CorporateOffers")}>
              <MainButton
                text="კორპორატიული შეთავაზებები"
                width="211px"
                height="65px"
                fontSize="18px"
              />
            </div>
            <div onClick={() => handleClick("SocialResponsibility")}>
              <MainButton
                text="სოციალური პასუხისმგებლობა"
                width="211px"
                height="65px"
                fontSize="18px"
              />
            </div>
          </div>
          <p>{originalText}</p>
        </div>

        <form className="BusinesContainer3InputCont">
          <div className="BusinesContainer3InputContInput">
            <input
              className="BusinesContainer3InputContInputs"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="თქვენი სახელი"
              required
            />
            <input
              className="BusinesContainer3InputContInputs"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              placeholder="თქვენი გვარი"
              required
            />
          </div>
          <input
            className="BusinesContainer3InputContInputs"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            type="text"
            placeholder="კომპანიის დასახელება"
            required
          />
          <input
            className="BusinesContainer3InputContInputs"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="text"
            placeholder="ტელეფონის ნომერი"
            required
          />
          <input
            className="BusinesContainer3InputContInputs"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="ელ.ფოსტა"
            required
          />
          <input
            className="BusinesContainer3InputContTextInp"
            type="text"
            placeholder="შეტყობინება ( დამატებითი დეტალებისთვის)"
            onChange={(e) => setText(e.target.value)}
            required
          />

          <div onClick={handleSubmit}>
            <MainButton
              type="submit"
              text="გაგზავნა"
              width="189px"
              height="55px"
              fontSize="18px"
              alignSelf="flex-end"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
