import React, { useEffect, useState } from "react";
import coursInfo from "../../../../Cours";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import MainButton from "../../../Buttons/MainButton";
import { NavLink, useNavigate } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

export default function PopularCours() {
  const [bodySize, setBodySize] = useState(window.innerWidth);
  let [smalResolution, setSmalResolution] = useState(0);
  let [seeMore, setSeeMore] = useState("");

  const navigate = useNavigate();
  const handleNavigation = (patch) => {
    navigate(patch);
  };

  useEffect(() => {
    const handleResize = () => {
      setBodySize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (bodySize <= 770) {
      setSmalResolution(3);
      setSeeMore("see more");
    } else if (bodySize <= 1200) {
      setSmalResolution(4);
      setSeeMore("see more");
    } else {
      setSmalResolution(6);
      setSeeMore("");
    }
  }, [bodySize]);

  return (
    <div className="CourseFullWrapper">
      <div className="sectionContainer2">
        <h1 className="sectionTitle">პოპულარული კურსები</h1>
        <div className="CoursSectionContainer">
          {coursInfo.coursInfo.slice(0, smalResolution).map((item) => (
            <div className="CoursSection" key={item.id}>
              <img
                className="coursImg"
                src={process.env.PUBLIC_URL + item.coursImg}
                alt="coursImg"
              />
              <div className="coursCartInfo">
                <div className="coursCartInfoTitle">
                  <h2 className="coursTitle">{item.coursTitle}</h2>
                  <div className="coursCartInfoTitleLessonQuantity">
                    <div className="coursCartInfoTitleQuantity">
                      <CalendarTodayOutlinedIcon className="course-icon" />
                      <h1>{item.lesson} ლექცია</h1>
                    </div>
                    <div className="coursCartInfoTitleQuantity">
                      <AccessAlarmIcon className="course-icon" />
                      <h1>{item.hour} საათი</h1>
                    </div>
                  </div>
                </div>
                <p className="sectionDescription">{item.description}</p>
                <NavLink
                  to={`/course/details/${item.id}`}
                  className="course-details-button"
                >
                  <MainButton
                    text="დეტალები"
                    width="125px"
                    height="45px"
                    fontSize="14px"
                  />
                </NavLink>
              </div>
            </div>
          ))}
        </div>
        <NavLink to="/courses" className="seeMoreLink">
          <small className="seeMore">{seeMore}</small>
        </NavLink>
      </div>
    </div>
  );
}
