import React, { useState } from "react";
import MainButton from "../../Buttons/MainButton";
import emailjs from "@emailjs/browser";
import Box from "@mui/system/Box";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MailIcon from "@mui/icons-material/Mail";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import "./ContactPage.css";
import "./ContactPageResponsive.css";

const ContactPageForm = () => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState();
  let [where_did_you_hear, setWhere_did_you_hear] = useState("");
  let [which_cours, setWhich_cours] = useState("");
  let [why_cours, setWhy_cours] = useState("");

  // console.log(phoneNumber);
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_9u8ccoq";
    const publichKey = "Tc_ySsnDKjbaH61v_";
    const templateId = "template_sa7a8hi";

    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phoneNumber,
      where_did_you_hear: where_did_you_hear,
      which_cours: which_cours,
      why_cours: why_cours,
    };

    emailjs
      .send(serviceID, templateId, templateParams, publichKey)
      .then((response) => {
        console.log("Emai sent succesfully!", response);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setWhere_did_you_hear("");
        setWhich_cours("");
        setWhy_cours("");
      })
      .catch((error) => {
        console.error("Error sending email: ", error);
      });
  };

  return (
    <Box className="form-main-wrapper">
      <div className="contact-info-wrapper">
        <h2 className="contact-info-title">საკონტაქტო ინფორმაცია</h2>
        <div className="contact-info">
          <div className="contact-each-container">
            <PhoneInTalkIcon className="contact-icon" />
            <p className="contact-info-address">+995 599 200 944</p>
          </div>
          <div className="contact-each-container">
            <MailIcon className="contact-icon" />
            <p className="contact-info-address">info@educity.ge</p>
          </div>
          <div className="contact-each-container">
            <CalendarTodayOutlinedIcon className="contact-icon" />
            <p className="contact-info-address">10:00-19:00</p>
          </div>
        </div>
      </div>

      <form className="form-registration">
        <div className="form-name-email-wrapper">
          <input
            type="text"
            placeholder="თქვენი სახელი"
            className="name-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="თქვენი ელ.ფოსტა"
            className="email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <input
          type="text"
          placeholder="ტელეფონის ნომერი"
          className="number-input"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="საიდან გაიგეთ ჩვენ შესახებ?"
          className="about-us-input"
          value={where_did_you_hear}
          onChange={(e) => setWhere_did_you_hear(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="რომელი კურსის გავლა გსურთ?"
          className="which-course-input"
          value={which_cours}
          onChange={(e) => setWhich_cours(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="რატომ გსურთ ამ კურსის გავლა?"
          className="why-input"
          value={why_cours}
          onChange={(e) => setWhy_cours(e.target.value)}
          required
        />

        <div onClick={handleSubmit} style={{ alignSelf: "flex-end" }}>
          <MainButton
            type="submit"
            text="კურსზე რეგისტრაცია"
            width="263px"
            height="65px"
            fontSize="18px"
          />
        </div>
      </form>
    </Box>
  );
};

export default ContactPageForm;
