import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StudentsCard from "./StudentsCard";

import AnaKartozia from "../../../../Assets/CourseMemberImages/ana kartozia.jpg";
import LiziAminashvili from "../../../../Assets/CourseMemberImages/lizi aminashvili.jpg";
import MedeaTandashvili from "../../../../Assets/CourseMemberImages/medea tandashvili.jpg";
import LanaWitelashvili from "../../../../Assets/CourseMemberImages/ლანა წითელაშვილი.jpg";
import LikaBedianidze from "../../../../Assets/CourseMemberImages/ლიკა ბედიანიძე.jpeg";
import VakoKobulashvili from "../../../../Assets/CourseMemberImages/VakoKobulashvili.jpg";

import "../MainPage.css";
import "../MainPageResponsive.css";

import "swiper/css";
import "swiper/css/effect-coverflow";

const LongScreenSwiper = () => {
  return (
    <Swiper
      spaceBetween={"110"}
      slidesPerView={"auto"}
      effect={"coverflow"}
      centeredSlides={true}
      loop
      grabCursor={true}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 1,
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        clickable: true,
      }}
      modules={[EffectCoverflow, Navigation]}
      className="swiper-container"
    >
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="Full-Stack დეველოპმენტის კურსი"
          description="მადლობას ვუხდი ჩემს ფრონტის და ბექის მენტორებს, რადგან უმოკლეს ვადაში სრულფასოვნად შემასწავლეს ვებ პროგრამირება. აქ მიღებული ცოდნით თამამად შემიძლია ვთქვა, რომ ვაკეთებ მომხმარებელზე მორგებულ და ფუნქციონალით სავსე ვებსაიტებს. ამ კომპანიაში მხოლოდ კოდის წერა არ მისწავლია, არამედ მისი თეორიულად მუშაობის საფუძვლებიც, რომლებიც ბევრმა გამოცდილმა დეველოპერმაც არ იცის კარგად. მათ ჩემს მოლოდინებს ბევრად გადაუსწრეს და მეტად კმაყოფილი დავრჩი ამ კურსის გავლით."
          image={VakoKobulashvili}
          name="ვაკო ქობულაშვილი"
          work="Full-Stack დეველოპმენტის კურსდამთავრებული"
        />
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="გრაფიკული დიზაინის კურსი"
          description="ვგრძნობ როგორ განვითარდი და უდიდეს მოტივაცის მაძლევს მენტორი, რაც ძალიან ბედნიერს მხდის!"
          image={AnaKartozia}
          name="ანა კარტოზია"
          work="Graphic Design კურსდამთავრებული"
        />
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="გრაფიკული დიზაინის კურსი"
          description="პირველ რიგში, მინდა უდიდესი მადლობა გადაგიხადოთ, რომ მომეცით შანსი, მიმეღო ამ პროგრამაში მონაწილეობა. რაც შეეხება მენტორს, იაკოს, არ ვიცი ასეთი სასწაული ლექტორი, ადამიანი, როგორ, სად იპოვეთ. სრული გულწრფელობით ვამბობ ამას. ამ ადამიანს აქვს ულევი ენერგია, ყოველთვის დაუზარელად პასუხობს ჩვენს უამრავ კითხვას ამდენი ხანია ვხვდებით ერთმანეთს და ერთხელ არ მიგვრძნია, რომ ოდნავ მაინც ჩვენმა კითხვებმა შეაწუხა, პირიქით. ასევე როდესაც დახმარება დამჭირდა, თავისი ინიციატივით, ღამის 12 საათზე ჩამერთო ონლაინ და დეტალურად გავიარეთ და მომაგვარებინა პრობლემა."
          image={LikaBedianidze}
          name="ლიკა ბედიანიძე"
          work="Graphic Design კურსდამთავრებული"
        />
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="გრაფიკული დიზაინის კურსი"
          description="მენტორი არის არაჩვეულებროვი ადამიანი არვიცი ამხელა ენერგია და ამასთანავე მუდამ დადებითი და პოზიტიური საიდან აქვს და ამასთანავე გვიან საღამოს, მიხარია რომ გავიცანი და უზომოდ დიდ მოტივაციას მაძლევს!"
          image={LiziAminashvili}
          name="ლიზი ამინაშვილი"
          work="Graphic Design კურსდამთავრებული"
        />
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="გრაფიკული დიზაინის კურსი"
          description="ძალიან დიდი მადლობა ედუსითის თითოეულ გუნდის წევრს, განსაკუთრებით კი იაკოსა და ლევანს, რომლების მუდამ ზრუნავენ თითოეულ სტუდენტზე, მათ განვითარებასა და პროგრამული თუ ტექნიკური ხარვეზების აღმოფხვრაზე!"
          image={MedeaTandashvili}
          name="მედეა თანდაშვილი"
          work="Graphic Design კურსდამთავრებული"
        />
      </SwiperSlide>
      <SwiperSlide className="swiper-slide">
        <StudentsCard
          title="Front-End დეველოპმენტის კურსი"
          description="მინდა დიდი მადლობა გადავუხადო ქეთის. მისი პროფესიონალიზმის და საქმისადმი დამოკიდებულების გამო მაქვს ძალიან დიდი მოტივაცია, ყოველ ლექციას დავესწრო და დავალებები მაქსიმალურად კარგად გავაკეთო. ის ყოველთვის ეხმარება ყველას და გადატვირთული გრაფიკის მიუხედავად, ყველასთვის პოულობს დროს დასახმარებლად. გარდა ამისა, ყოველთვის გვამხნევებს და უფრო მეტ მოტივაციას გვაძლევს. ასევე დიდი მადლობა ედუსითის. ძალიან მიხარია, რომ შანსი მომეცა ამ კურსს დავსწრებოდი. მოლოდინებს გადააჭარბა."
          image={LanaWitelashvili}
          name="ლანა წითელაშვილი"
          work="Front-End დეველოპმენტის კურსდამთავრებული"
        />
      </SwiperSlide>

      <div className="slider-controler">
        <div className="swiper-button-prev slider-arrow">
          <ArrowBackIosIcon />
        </div>
        <div className="swiper-button-next slider-arrow">
          <ArrowForwardIosIcon />
        </div>
      </div>
    </Swiper>
  );
};

export default LongScreenSwiper;
