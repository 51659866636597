import React from "react";
import coursInfo from "../../../Cours.json";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import coursImg from "../../../Assets/Images/coursImg.jpg";
import MainButton from "../../Buttons/MainButton";
import { NavLink } from "react-router-dom";

import "../MainPage/MainPage.css";
import "./AllCoursesPage.css";

export default function AllcoursContainer() {
  return (
    <div className="sectionContainer2 transparent">
      <div className="CoursSectionContainer transparent">
        {coursInfo.coursInfo.map((item) => (
          <div className="CoursSection" key={item.id}>
            <img className="coursImg" src={item.coursImg} alt="coursImg" />
            <div className="coursCartInfo">
              <div className="coursCartInfoTitle">
                <h2 className="coursTitle">{item.coursTitle}</h2>
                <div className="coursCartInfoTitleLessonQuantity">
                  <div className="coursCartInfoTitleQuantity">
                    <CalendarTodayOutlinedIcon className="course-icon" />
                    <h1>{item.lesson} ლექცია</h1>
                  </div>
                  <div className="coursCartInfoTitleQuantity">
                    <AccessAlarmIcon className="course-icon" />
                    <h1>{item.hour} საათი</h1>
                  </div>
                </div>
              </div>
              <p className="sectionDescription">{item.description}</p>
              <NavLink to={`/course/details/${item.id}`}>
                <MainButton
                  text="დეტალები"
                  width="125px"
                  height="45px"
                  fontSize="14px"
                />
              </NavLink>
            </div>
          </div>
        ))}
      </div>

      <h2 className="children-program-title">საბავშვო პროგრამა</h2>
      <div className="CoursSection">
        <img
          className="coursImg"
          src="/CourseBannerImages/EduSchool.png"
          alt="coursImg"
        />
        <div className="coursCartInfo">
          <div className="coursCartInfoTitle">
            <h2 className="coursTitle">EduSchool-ის პროგრამა</h2>
            <div className="coursCartInfoTitleLessonQuantity">
              <div className="coursCartInfoTitleQuantity">
                <CalendarTodayOutlinedIcon className="course-icon" />
                <h1>არჩევით</h1>
              </div>
              <div className="coursCartInfoTitleQuantity">
                <AccessAlarmIcon className="course-icon" />
                <h1>არჩევით</h1>
              </div>
            </div>
          </div>
          <p className="sectionDescription">
            სასწავლო პროცესი ხორციელდება უნიკალური მიდგომით და მოიცავს როგორც
            ბავშვებისათვის მომავლის პროფესიების შესწავლას, აგრეთვე სხვადასხვა
            საჭირო უნარჩვევების გამომუშავებას, რაც მოსწავლეს სამომავლოდ
            დაეხმარება წარმატებული კარიერის შექმნაში. გთავაზობთ ყველაზე სანდო და
            მოსწავლეზე ორიენტირებულ გრაფიკული დიზაინის პროგრამას საქართველოში.
            პროგრამა მოიცავს Adobe Photoshop-ში და Adobe Illustrator-ში
            მუშაობას. მიზანია 0-დან სამუშაო მზადყოფნამდე მიიყვანოს თითოეული
            მოსწავლე.
          </p>
          <NavLink to="/eduschool">
            <MainButton
              text="დეტალები"
              width="125px"
              height="45px"
              fontSize="14px"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
