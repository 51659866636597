import React from "react";
import BusinesImg from "../../../Assets/Images/BusinessCont1Img.png";
import BusinessInfo from "./BusinessInfo";
import Partner from "../MainPage/Main/Partner";

import "./BusinessPage.css";

function Business() {
  return (
    <div className="BusinessFullContainer">
      <div className="BusinesContainer1">
        <div className="BusinesContainer1Cont1">
          <h1>შექმენი გამორჩეული ტალანტებისგან შემდგარი გუნდი</h1>
          <p>
            გახდი ედუსითის პარტნიორი, დაზოგე დრო თანხა და კომპანიისთვის
            მნიშვნელოვანი სხვა რესურსები, შექმენი გამორჩეული ტალანტებისგან
            შემდგარი გუნდი.
          </p>
        </div>
        <div className="BusinesContainer1Cont1Img">
          <img src={BusinesImg} alt="Business" />
        </div>
      </div>

      <div className="business-partners">
        <Partner />
      </div>

      <BusinessInfo />
    </div>
  );
}

export default Business;
