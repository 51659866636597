import React, { useState, useEffect } from "react";
import EducityLogo from "../../../../Assets/Images/educity.png";
import SocialIcons from "../SocialIcons";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/system/Box";
import { NavLink } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

const Navbar = () => {
  const [boxShadow, setBoxShadow] = useState("none");
  // Mobile navbar menu toggle
  const [navbarToggle, setNavbarToggle] = useState(false);

  const handleToggle = () => {
    if (navbarToggle) {
      setNavbarToggle(false);
    } else if (!navbarToggle) {
      setNavbarToggle(true);
    }
  };

  const listenScrollEvent = () => {
    window.scrollY > 50
      ? setBoxShadow("rgba(0, 0, 0, 0.1) 0px 4px 12px")
      : setBoxShadow("none");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <Box
      component="nav"
      className="navbar-wrapper"
      sx={{
        boxShadow: boxShadow,
      }}
    >
      <Box className="navbar">
        <NavLink to="/">
          <img src={EducityLogo} alt="Company Logo" className="navbar-logo" />
        </NavLink>

        <ul className="navbar-link-wrapper">
          <li>
            <NavLink to="/aboutus">ჩვენ შესახებ</NavLink>
          </li>
          <li>
            <NavLink to="/courses">კურსები</NavLink>
          </li>
          <li>
            <NavLink to="/business">ბიზნესისთვის</NavLink>
          </li>
          <li>
            <NavLink to="/contacts">კონტაქტი</NavLink>
          </li>
        </ul>

        <div className="navbar-social-icons">
          <SocialIcons />
        </div>

        <div className="mobile-menu-icon" onClick={handleToggle}>
          <MenuIcon />
        </div>
      </Box>

      {/* Responsive links for mobile and tablet */}
      <ul
        className="navbar-mobile-link-wrapper"
        style={navbarToggle ? { display: "flex" } : { display: "none" }}
      >
        <NavLink to="/">
          <img src={EducityLogo} alt="Company Logo" className="navbar-logo" />
        </NavLink>

        <li>
          <NavLink to="/aboutus">ჩვენ შესახებ</NavLink>
        </li>
        <li>
          <NavLink to="/courses">კურსები</NavLink>
        </li>
        <li>
          <NavLink to="/business">ბიზნესისთვის</NavLink>
        </li>
        <li>
          <NavLink to="/contacts">კონტაქტი</NavLink>
        </li>

        <SocialIcons />
      </ul>
    </Box>
  );
};

export default Navbar;
