import React from "react";
import HeaderWrapper from "./Header/HeaderWrapper";
import MainWrapper from "./Main/MainWrapper";
import Footer from "./Footer/Footer.jsx";

const MainPageWrapper = () => {
  return (
    <>
      <HeaderWrapper />

      <MainWrapper />

      <Footer />
    </>
  );
};

export default MainPageWrapper;
