import React from "react";
import Navbar from "../MainPage/Header/Navbar";
import PageTitles from "../PageTitles";
import cont1Img from "../../../Assets/Images/AboutUsCont1Img.png";
import AboutUsCont21Img from "../../../Assets/Images/AboutUsCont21Img.png";
import AboutUsCont22Img from "../../../Assets/Images/AboutUsCont22img.png";
import AboutUsCont23Img from "../../../Assets/Images/AboutUsCont23Img.png";
import Decide from "../MainPage/Main/Decide";
import Footer from "../MainPage/Footer/Footer";

import "./AboutUsPage.css";

export default function AboutUs() {
  return (
    <>
      <Navbar />

      <PageTitles whitetext="მთავარი /" orangetext=" ჩვენ შესახებ" />
      <div className="all-courses-header-background"></div>

      <div className="AboutUsCont1">
        <div className="AboutUsCont1Info">
          <h1>რატომ უნდა შემოგვიერთდე?</h1>
          <p>
            ედუსითი 2023 წელს დაარსდა, იმ იდეით, რომ ყოფილიყო ყველაზე სანდო და
            მომხმარებელზე ორიენტირებული აკადემია.
          </p>
          <p>
            EduCity მომხმარებელს სთავაზობს პროფესიულ პროგრამებს მარკეტინგის,
            ტექნოლოგიების, დიზაინისა და მენეჯმენტის მიმართულებით.
          </p>
          <p>
            სწავლება ყოველთვის დაფუძნებულია პრაქტიკულ მაგალითებზე. რის
            შესაძლებლობასაც გვაძლევს ჩვენ სფეროებში მრავალწლიანი გამოცდილების
            მქონე, Senior პოზიციებზე მყოფი მენტორები.
          </p>
        </div>
        <div className="AboutUsCont1ImgContainer">
          <img src={cont1Img} alt="cont1Img" className="htmlImage" />
        </div>
      </div>

      <div className="AboutUsCont2">
        <div className="AboutUsCont2Cont1">
          <h1 className="AboutUsCont2Cont1H1">20+</h1>
          <h2 className="AboutUsCont2Cont1H2">სპიკერი</h2>
          <p className="AboutUsCont2Cont1P">
            ჩვენ ვთანამშრომლობთ ციფრულ ინდუსტრიებში აღიარებულ მენტორებთან
          </p>
        </div>
        <div className="AboutUsCont2Cont1">
          <h1 className="AboutUsCont2Cont1H1">25+</h1>
          <h2 className="AboutUsCont2Cont1H2">პარტნიორი</h2>
          <p className="AboutUsCont2Cont1P">
            სტუდენტთა დასაქმების ხელშეწყობის მიზნით ვთანამშრომლობთ სხვადასხვა
            წამყვან კომპანიასთან
          </p>
        </div>
        <div className="AboutUsCont2Cont1">
          <h1 className="AboutUsCont2Cont1H1">100+</h1>
          <h2 className="AboutUsCont2Cont1H2">სტუდენტი</h2>
          <p className="AboutUsCont2Cont1P">
            ჩვენ წარმატებით გადავამზადეთ 100-ზე მეტი სტუდენტი და ხელი შევუწყეთ
            მათ პროფესიულ განვითარებას
          </p>
        </div>
      </div>

      <div className="AboutUsCont3">
        <div className="AboutUsCont3Cont1">
          <div className="AboutUsCont3Cont1Cont1">
            <img
              className="AboutUsCont3Cont1Img"
              src={AboutUsCont21Img}
              alt="AboutUsCont21Img"
            />
            <img
              className="AboutUsCont3Cont1Img"
              src={AboutUsCont22Img}
              alt="AboutUsCont22Img"
            />
          </div>
          <img
            className="AboutUsCont3Cont1Imgs"
            src={AboutUsCont23Img}
            alt="AboutUsCont23Img"
          />
        </div>
        <div className="AboutUsCont3Cont2">
          <h1>EduCity-ის მიზანი</h1>
          <p>
            ჩვენი მიზანია, თითოეული მოსწავლე მივიყვანოთ 0-დან სამუშაო
            მზადყოფნამდე. ვასწავლოთ, რაც შეიძლება მეტ ადამიანს, ის უნარები,
            რომლებიც მათ მოთხოვნას გაზრდის დღევანდელ ტექნოლოგიურ ბაზარზე და
            შეძლებენ ციფრულ მომავალთან ადაპტირებას. თითოეული მენტორი მოსწავლეს
            უდგება ინდივიდუალურად და მზად არის ბოლომდე მიყვეს და არ
            შემოიფარგლება საგაკვეთილო დროით.
          </p>
          <h1>რატომ პროგრამა და არა კურსი</h1>
          <p>
            ედუსითიში არ გვაქვს კურსები, გვაქვს პროგრამები ჩვენი გუნდი იმაზე
            მეტს აკეთებს ვიდრე უბრალოდ ლექციებია. თითოეული მენტორი მზადაა
            დამატებით აგიხსნას გაუგებარი საკითხები, შეგხვდნენ ინდივიდუალურად,
            გვერდში დაგიდგნენ, როგორც კარიერული, ასევე მორალური თვალსაზრისით.
            პროგრამასთან ერთად ხდები ჩვენი ონლაინ საზოგადოების (Community-ის)
            წევრი, რომელიც შექმნილია იმისთვის, რომ სწავლების პერიოდი კიდევ უფრო
            საინტერესო და შემეცნებითი გახდეს.
          </p>
        </div>
      </div>

      <Decide />

      <Footer />
    </>
  );
}
