import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import SocialIcons from "../SocialIcons";
import { NavLink } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

export default function Footer() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_pcdo33y",
        "template_hovho9s",
        form.current,
        "el0GIfbIPcZM4C10m"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log("erroraskfl", error.text);
        }
      );
  };

  return (
    <footer className="footers">
      <div className="leftContainer">
        <div className="leftContainerInfo">
          <div className="footerSection footerSection1">
            <h1 className="mainH1">გვერდები</h1>
            <p>
              <NavLink to="/aboutus" className="navLink">
                ჩვენს შესახებ
              </NavLink>
            </p>
            <p>
              <NavLink to="/courses" className="navLink">
                კურსები
              </NavLink>
            </p>
            <p>
              <NavLink to="/business" className="navLink">
                ბიზნესისთვის
              </NavLink>
            </p>
          </div>
          <div className="footerSection footerSection2">
            <h1 className="mainH1">კონტაქტი</h1>
            <p>
              <NavLink to="/contacts">იმეილი</NavLink>
            </p>
            <p>
              <NavLink to="/contacts">მობილური</NavLink>
            </p>
          </div>
        </div>
        <div className="leftContainerInput">
          <div className="footerInput">
            <h1 className="mainH1">მოგვწერეთ</h1>
            <div className="footerInputContainer">
              <form ref={form} onSubmit={sendEmail}>
                <input name="message" type="email" placeholder="ელ.ფოსტა" />

                <button type="submit">გაგზავნა</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <SocialIcons />
    </footer>
  );
}
