import React from "react";
import Business from "./Business";
import Navbar from "../MainPage/Header/Navbar";
import PageTitles from "../PageTitles";
import Footer from "../MainPage/Footer/Footer";

const BusinessPageWrapper = () => {
  return (
    <>
      <Navbar />

      <PageTitles whitetext="მთავარი /" orangetext=" ბიზნესისთვის" />
      <div className="all-courses-header-background"></div>

      <Business />

      <Footer />
    </>
  );
};

export default BusinessPageWrapper;
