import React from "react";
import Box from "@mui/system/Box";
import Students from "./Students";
import Partner from "./Partner";
import PopularCours from "./PopularCours";
import Consultation from "./Consultation";
import Decide from "./Decide";
// import CardSection from "./CardSection";

const MainWrapper = () => {
  return (
    <Box component="main" className="main-wrapper">
      {/* Partners Section */}
      <Partner />
      <PopularCours />

      <Consultation />
      {/* <CardSection /> */}
      <Students />

      <Decide />
    </Box>
  );
};

export default MainWrapper;
