import { Box } from "@mui/material";
import React from "react";
import EduSchoolSwiper from "./EduSchoolSwiper";
import MainButton from "../../Buttons/MainButton";

import "./EduSchoolPage.css";
import "./EduSchoolPageResponsive.css";
import { Link } from "react-router-dom";

const EduSchoolContent = () => {
  return (
    <Box component="main" className="eduschool-main">
      <div className="eduschool-courseinfo-wrapper">
        <div className="eduschool-courseinfo-mentor">
          <EduSchoolSwiper />
        </div>

        <div className="eduschool-courseinfo-mentor eduschool-courseinfo-mentor2">
          <div className="eduschool-course-prices">
            <div>
              <h4 className="eduschool-course-title">კვირაში 1 ლექცია</h4>
              <div className="eduschool-course-priceinfo">
                <div>თვეში 170₾</div> <div>3 თვის განმავლობაში</div>
              </div>
            </div>
            <div>
              <h4 className="eduschool-course-title">კვირაში 2 ლექცია</h4>
              <div className="eduschool-course-priceinfo">
                <div>თვეში 250₾</div> <div>3 თვის განმავლობაში</div>
              </div>
            </div>
            <div>
              <h4 className="eduschool-course-title">კვირაში 3 ლექცია</h4>
              <h4 className="eduschool-course-title">
                შედის ინგლისური ენის შესწავლა
              </h4>
              <div className="eduschool-course-priceinfo">
                <div>თვეში 350₾</div> <div>3 თვის განმავლობაში</div>
              </div>
            </div>
          </div>

          <div className="eduschool-goal">
            <h2 className="eduschool-goal-title">
              EduSchool-ის პროგრამის მიზანი
            </h2>
            <p className="eduschool-goal-description">
              პროგრამა მოიცავს ბავშვებისათვის მომავლის პროფესიების შესწავლას,
              როგორიც არის პროგრამირება, UI/UX-დიზაინი, გრაფიული დიზაინი, და
              სხვა. ამასთან ერთად ითვალისწინებს: სასაუბრო ინგლისურის
              გაუმჯობესებას, ჯანსაღი ცხოვრების წესის დანერგვას, სწორი ჩვევების
              გამომუშავებას, ტრენინგებს თუ როგორ დავიწყოთ საკუთარი ბიზნესი და
              მიზნების სწორად დასახვას. სასწავლო პროცესი ხორციელდება უნიკალური
              მიდგომით რომელიც ითვალისწინებს სწავლებას სხვადასხვა სახალისო და
              სპორტული აქტივობებით. სასწავლო გეგმა შემუშავებული და მორგებულია
              ბავშვთა ასაკობრივ ჯგუფზე, პროგრამა აგებულია არამარტო კონკრეტული
              პროფესიის შესწავლაზე არამედ, სხვადასხვა საჭირო უნარჩვევების
              გამომუშავებაზე, რაც მოსწავლეს სამომავლოდ დაეხმარება წარმატებული
              კარიერის შექმნაში.
            </p>
          </div>
        </div>

        <div className="eduschool-buttons-wrapper">
          <Link to="/eduschool-register">
            <MainButton
              text="დარეგისტრირდი"
              width="306px"
              height="52px"
              fontSize="18px"
            />
          </Link>

          {/* button for download pdf silabus */}
          <a href="/Silabuses/EduSchool.pdf" download="EduSchool-ის სილაბუსი">
            <button className="CursCostBtn" style={{ margin: 0 }}>
              სილაბუსის ჩამოტვირთვა
            </button>
          </a>
        </div>
      </div>

      <div className="educity-additional-modules-wrapper">
        <h3 className="educity-additional-modules-title">
          არჩეულ პროფესიას მოჰყვება შემდეგი დამატებითი მოდულები
        </h3>

        <div className="educity-additional-modules">
          <div>
            <div className="educity-additional-module">
              ჯანსაღი ცხოვრების წესი
            </div>
            <div className="educity-additional-module">
              სწორი ჩვევების გამომუშავება
            </div>
            <div className="educity-additional-module">
              როგორ დავიწყოთ საკუთარი ბიზნესი
            </div>
          </div>
          <div>
            <div className="educity-additional-module">
              მიზნების სწორად დასახვა
            </div>
            <div className="educity-additional-module">
              შაბათის აქტივობები (ჰაკათონები)
            </div>
            <div className="educity-additional-module">
              ეფექტური კომუნიკაცია
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default EduSchoolContent;
