import React from "react";
import Navbar from "./Navbar";
import Box from "@mui/system/Box";
import "../MainPage.css";
import "../MainPageResponsive.css";
import HeaderInfo from "./HeaderInfo";

const HeaderWrapper = () => {
  return (
    <Box component="header" className="header-wrapper">
      <Navbar />

      <HeaderInfo />
    </Box>
  );
};

export default HeaderWrapper;
