import React from "react";
import MainButton from "../../Buttons/MainButton";
import { Link } from "react-router-dom";

import "./CoursePage.css";
import "./CoursePageResponsive.css";

export default function CoursCost({ products }) {
  return (
    <>
      <div className="coursCostContainer">
        <img src={products.mentorImg} alt="mentorImg" />
        <div>
          <h1 className="lectorName">{products.mentor}</h1>
          <div className="coursTime">
            <div className="coursTimeCont coursQuantity">
              <h2>{products.lesson}</h2>
              <h2>ლექცია</h2>
            </div>
            <div className="coursTimeCont coursQuantity">
              <h2>{products.hour}</h2>
              <h2>საათი</h2>
            </div>
            <div className="coursTimeCont coursQuantity">
              <h2>{products.month}</h2>
              <h2>თვე</h2>
            </div>
          </div>
          <h2 className="CoursCostH2">აუდიტორიული სწავლება</h2>
          <div className="CoursCostCont">
            <h2>თვეში 390₾</h2>
            <h2>{products.month} თვის განმავლობაში</h2>
          </div>
          <h2 className="CoursCostH2">ონლაინ სწავლება</h2>
          <div className="CoursCostCont">
            <h2>თვეში 290₾</h2>
            <h2>{products.month} თვის განმავლობაში</h2>
          </div>

          {/* button for enter registration form */}
          <Link to="/course-register">
            <MainButton
              text="დარეგისტრირდი"
              width="306px"
              height="52px"
              fontSize="18px"
            />
          </Link>

          {/* button for download pdf silabus */}
          <a href={products.silabus} download={products.coursTitle}>
            <button className="CursCostBtn">სილაბუსის ჩამოტვირთვა</button>
          </a>
        </div>
      </div>
    </>
  );
}
