import React from "react";
import Box from "@mui/system/Box";
import "../MainPage.css";
import "../MainPageResponsive.css";
import LongScreenSwiper from "./LongScreenSwiper";
import MobileSwiper from "./MobileSwiper";

const Students = () => {
  return (
    <Box component="section" className="students-wrapper">
      <h3 className="students-title">რას ამბობენ კურსდამთავრებულები?</h3>

      {/* for every device. not mobile */}
      <LongScreenSwiper />

      {/* only for mobile device */}
      <MobileSwiper />
    </Box>
  );
};

export default Students;
