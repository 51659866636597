import React from "react";
import Navbar from "../MainPage/Header/Navbar";
import AllCoursesInfo from "./AllCoursesInfo";
import Footer from "../MainPage/Footer/Footer";
import Decide from "../MainPage/Main/Decide";
import PageTitles from "../PageTitles";

import "./AllCoursesPage.css";

const AllCoursesPageWrapper = () => {
  return (
    <>
      <Navbar />

      <PageTitles whitetext="მთავარი /" orangetext=" კურსები" />
      <div className="all-courses-header-background"></div>

      <AllCoursesInfo />

      <Decide />

      <Footer />
    </>
  );
};

export default AllCoursesPageWrapper;
