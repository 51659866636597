import React from "react";
import "../MainPage.css";
import "../MainPageResponsive.css";

const StudentsCard = ({ title, description, image, name, work }) => {
  return (
    <>
      <div>
        <h3 className="students-card-title">{title}</h3>
        <p className="students-card-description">{description}</p>
      </div>

      <div className="students-person-wrapper">
        <img src={image} alt="student-photo" className="student-photo" />
        <div className="student-info-wrapper">
          <h4 className="student-name">{name}</h4>
          <p className="student-work">{work}</p>
        </div>
      </div>
    </>
  );
};

export default StudentsCard;
