import React from "react";
import Box from "@mui/system/Box";
import MainButton from "../../../Buttons/MainButton";
import HeaderInfoImage from "../../../../Assets/Images/header-info-image.png";
import { NavLink } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

const HeaderInfo = () => {
  return (
    <Box className="header-info-wrapper" sx={{ marginTop: "129px" }}>
      <Box className="header-info-margin-container">
        <Box className="header-info-text-wrapper">
          <h2 className="header-info-title">
            ისწავლე, განვითარდი და{" "}
            <span style={{ color: "#E2AA18" }}>მიაღწიე</span> ჩვენთან ერთად!
          </h2>

          <p className="header-info-text">
            დაეუფლე ციფრულ ტექნოლოგიებს 0-დან, ისარგებლე დაფინანსებული კურსებით
            და რაც მთავარია, ისწავლე ნებისმიერი ადგილიდან. ჩვენთან მიიღებ
            სრულყოფილ განათლებას და დასაქმების შანსს.
          </p>

          <NavLink to="/courses">
            <MainButton
              text="ჩაეწერე კურსზე"
              width="200px"
              height="50px"
              fontSize="16px"
            />
          </NavLink>
        </Box>
        <img
          src={HeaderInfoImage}
          alt="robot-girl-image"
          className="header-info-image"
        />
      </Box>
    </Box>
  );
};

export default HeaderInfo;
