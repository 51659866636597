import React from "react";

import "./CoursePage.css";
import "./CoursePageResponsive.css";

export default function CoursDetal({ products }) {
  return (
    <div className="CoursDetalContainer">
      <div className="CoursDetalRecentagle">
        <h2>{products.whichCourse}</h2>
      </div>
      <div className="CoursDetalDescription">
        <div className="CoursDetalDescriptionCont CoursDetalDecription">
          <h2>კურსის აღწერა</h2>
          <p>{products.detailDescription}</p>
        </div>
        <div className="CoursDetalDescriptionCont CoursBenefit">
          <h2>ვისთვის არის კურსი</h2>
          <p>{products.programForWho}</p>
        </div>
        <div className="CoursDetalDescriptionCont CoursAfter">
          <h2>რა გეცოდინება კურსის გავლის შემდეგ</h2>
          <ul>
            {products.knowledgeAfterCourse.map((line, index) => {
              return <li key={index}>{line}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
