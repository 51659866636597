import React from "react";
import Decide from "../MainPage/Main/Decide";
import Navbar from "../MainPage/Header/Navbar";
import Footer from "../MainPage/Footer/Footer";
import CourseCost from "./CourseCost";
import CourseDetail from "./CourseDetail";
import { useParams } from "react-router-dom";
import coursInfo from "../../../Cours.json";
import { Helmet } from "react-helmet";

import "./CoursePage.css";
import "./CoursePageResponsive.css";

const CoursePageWrapper = () => {
  const { id } = useParams();
  let products = coursInfo.coursInfo.find((product) => product.id == id);

  const description = `შეისწავლეთ ${products.whichCourse} სწრაფად და ხარისხიანად პროფესიონალი მენტორების დახმარებით. ისინი მაქსიმალურად შეეცდებიან ნულიდან სრულფასოვნად შეგასწავლოთ ეს პროფესია.`;

  return (
    <>
      <Helmet>
        <title>Educity - {products.whichCourse}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Navbar />
      <div className="CoursDescriptionContainerContainer">
        <CourseCost className="CoursCost" products={products} />
        <CourseDetail className="CoursDetal" products={products} />
      </div>

      <Decide />

      <Footer />
    </>
  );
};

export default CoursePageWrapper;
