import React from "react";
import "./MainButton.css";

const Button = ({ text, width, height, fontSize, alignSelf }) => {
  return (
    <button
      className="main-button"
      style={{
        width: width,
        height: height,
        fontSize: fontSize,
        alignSelf: alignSelf,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
