import React from "react";
import MainButton from "../../../Buttons/MainButton";
import { NavLink } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

export default function Decide() {
  return (
    <div className="DecideContainer">
      <h1>რა მიმართულებით ხართ დაინტერესებული?</h1>
      <NavLink to="/contacts">
        <MainButton
          text="გაიარე კონსულტაცია"
          width="245px"
          height="50px"
          fontSize="15px"
        />
      </NavLink>
    </div>
  );
}
