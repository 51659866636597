import React from "react";

import "./PageTitles.css";

const PageTitles = ({ whitetext, orangetext, orangetextDescription }) => {
  return (
    <div className="page-titles-wrapper">
      <h1 className="page-title">
        {whitetext} <span className="orange">{orangetext}</span> <br />
        <span className="orange">{orangetextDescription}</span>
      </h1>
    </div>
  );
};

export default PageTitles;
