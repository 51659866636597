import React from "react";
import Box from "@mui/system/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import "./MainPage.css";

const SocialIcons = () => {
  return (
    <div>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#160e3d" />
          <stop offset={1} stopColor="#3877cc" />
        </linearGradient>
      </svg>
      <Box className="social-icons-wrapper">
        {/* Facebook Icon */}
        <a
          href="https://www.facebook.com/profile.php?id=100088498458265&mibextid=ibOpuV"
          target="_blank"
        >
          <button className="social-icon">
            <FacebookRoundedIcon sx={{ fill: "url(#linearColors)" }} />
          </button>
        </a>
        {/* Instagram Icon */}
        <a
          href="https://www.instagram.com/__educity?igsh=OXAzNTFkMHR3Znc0"
          target="_blank"
        >
          <button className="social-icon">
            <InstagramIcon sx={{ fill: "url(#linearColors)" }} />
          </button>
        </a>

        {/* YouTube Icon */}
        <a
          href="https://youtube.com/@__educity?si=YzLmsxXQMySUaXrk"
          target="_blank"
        >
          <button className="social-icon">
            <YouTubeIcon
              sx={{ fill: "url(#linearColors)", fontSize: "35px !important" }}
            />
          </button>
        </a>

        {/* Linkedin Icon */}
        <a href="https://www.linkedin.com/company/educity1/" target="_blank">
          <button className="social-icon">
            <LinkedInIcon sx={{ fill: "url(#linearColors)" }} />
          </button>
        </a>
      </Box>
    </div>
  );
};

export default SocialIcons;
