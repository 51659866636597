import React from "react";
import ConsultationMainImg from "../../../../Assets/Images/ConsultationMainImg.png";
import UnderBackgroundImgs from "../../../../Assets/Images/underBackgroundImg.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MainButton from "../../../Buttons/MainButton";
import { NavLink } from "react-router-dom";

import "../MainPage.css";
import "../MainPageResponsive.css";

export default function Consultation() {
  return (
    <div>
      <div className="UnderBackgroundImgsContainer">
        <img src={UnderBackgroundImgs} alt="UnderBackgroundImgs" />
      </div>
      <div className="ConsultationContainer">
        <div className="ConsultationMainImg">
          <img
            className="ConsultationMainImgs"
            src={ConsultationMainImg}
            alt="ConsultationMainImg"
          />
        </div>
        <div className="ConsultationPageInformation">
          <h1 className="ConsultationPageInformationTitle">
            გაიარე კონსულტაცია და გააკეთე სწორი არჩევანი
          </h1>
          <p className="ConsultationPageInformationDescription">
            მოგვწერე ან დაგვირეკე, ჩვენი სერვის მენეჯერი მოგაწვდის სრულყოფილ
            ინფორმაციას კურსების შესახებ და დაგეხმარება სწორი გადაწყვეტილების
            მიღებაში.
          </p>

          <div className="ConsultationPageInformationContact">
            <div className="ConsultationsInfoCont">
              <MailOutlineIcon className="ConsultIcon" />
              <div className="emailcontInfo">
                <h1 className="ConsultationsTitl">ელ.ფოსტა</h1>
                <h2 className="ConsultationsDes">info@educity.ge</h2>
              </div>
            </div>

            <div className="ConsultationsInfoCont">
              <PhoneAndroidIcon className="ConsultIcon" />
              <div className="emailcontInfo">
                <h1 className="ConsultationsTitl">ტელეფონი</h1>
                <h2 className="ConsultationsDes">599 200 944</h2>
              </div>
            </div>

            <div className="ConsultationsInfoCont">
              <AccessTimeIcon className="ConsultIcon" />
              <div className="emailcontInfo">
                <h1 className="ConsultationsTitl">სამუშაო საათები</h1>
                <h2 className="ConsultationsDes">ორშ-პარ(10:00-19:00)</h2>
              </div>
            </div>
          </div>

          <div className="consultation-button">
            <NavLink to="/courses">
              <MainButton
                text="ჩაეწერე კურსზე"
                width="200px"
                height="50px"
                fontSize="14px"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
