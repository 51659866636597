import React from "react";
import Navbar from "../MainPage/Header/Navbar";
import PageTitles from "../PageTitles";
import Footer from "../MainPage/Footer/Footer";
import ContactPageForm from "./ContactPageForm";

import "./ContactPage.css";

const ContactPageWrapper = () => {
  return (
    <>
      <Navbar />
      <PageTitles whitetext="მთავარი /" orangetext=" კონტაქტი" />
      <div className="contacts-header-background"></div>

      <ContactPageForm />

      <Footer />
    </>
  );
};

export default ContactPageWrapper;
