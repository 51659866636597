import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import CourseMentors from "../../../CourseMentors.json";
import { Autoplay } from "swiper/modules";

import "./EduSchoolPage.css";
import "./EduSchoolPageResponsive.css";

import "swiper/css";

const EduSchoolSwiper = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  SwiperCore.use([Autoplay]);

  return (
    <>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={"1"}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        onSlideChangeTransitionEnd={handleSlideChange}
        loop
        className="eduschool-swiper-container"
      >
        {CourseMentors.map((data, index) => (
          <SwiperSlide className="eduschool-swiper-slide" key={index}>
            <img src={data.mentorIMG} alt="mentorIMG" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div>
        <h3 className="mentor-swiper-title">
          მენტორი <br />
          <br /> {CourseMentors[activeIndex].mentorName}
        </h3>
      </div>
    </>
  );
};

export default EduSchoolSwiper;
