import React from "react";
import Navbar from "../MainPage/Header/Navbar";
import PageTitles from "../PageTitles";
import Footer from "../MainPage/Footer/Footer";
import EduSchoolContent from "./EduSchoolContent";

import "./EduSchoolPage.css";
import "./EduSchoolPageResponsive.css";

const EduSchoolPageWrapper = () => {
  return (
    <>
      <Navbar />
      <PageTitles
        orangetext="EduSchool"
        orangetextDescription="საბავშვო პროგრამა"
      />
      <div className="contacts-header-background"></div>

      <EduSchoolContent />

      <Footer />
    </>
  );
};

export default EduSchoolPageWrapper;
