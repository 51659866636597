import React from "react";
import "../MainPage.css";
import "../MainPageResponsive.css";

export default function Partner() {
  const PartnerArray = [
    require("../../../../Assets/PartnerImages/delisi-hub-partner.png"),
    require("../../../../Assets/PartnerImages/tbilisis-meria-partner.png"),
    require("../../../../Assets/PartnerImages/black-sea-arena-partner.png"),
    require("../../../../Assets/PartnerImages/elit-electronics-partner.png"),
    require("../../../../Assets/PartnerImages/up-way-partner.png"),
    require("../../../../Assets/PartnerImages/ideal-partner.png"),
    require("../../../../Assets/PartnerImages/unison-partner.png"),
    require("../../../../Assets/PartnerImages/kutaisi-inn-partner.png"),
    require("../../../../Assets/PartnerImages/mc-drive-partner.png"),
  ];
  const extendedPartnerArray =
    PartnerArray.concat(PartnerArray).concat(PartnerArray);

  return (
    <div className="Partner">
      <h1>პარტნიორი კომპანიები</h1>
      <p>
        ჩვენ ვთანამშრომლობთ, როგორც კერძო სააგენტოებთან და კომპანიებთან,
        ამასთანავე სახელმწიფო უწყებებთან
      </p>
      <div className="partnerIconContainer">
        {extendedPartnerArray.map((item, index) => (
          <img
            className="partnerImg"
            src={item}
            key={index}
            alt="PartnerLogo"
          />
        ))}
      </div>
    </div>
  );
}
